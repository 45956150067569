import { Injectable, inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { SocialAuthService, SocialUser } from '@abacritt/angularx-social-login';
import { HeaderParameter } from '@sulser-print/constants/header-parameters';
import { Response } from '@sulser-print/models/response';
import { catchError, map, switchMap, tap } from 'rxjs';

import { RequestService } from './request.service';
import { TokenService } from './token.service';
import { UserPermissionsStore } from './user-permissions-store';
import { UserPermissionsService } from './user-permissions.service';

export interface LoginRequest {
	email: string;
	password: string;
	remember: boolean;
}

export interface LoginResponse {
	refreshToken: string;
	token: string;
}

@Injectable({
	providedIn: 'root',
})
export class AuthService extends RequestService {
	private readonly activatedRoute = inject(ActivatedRoute);

	private readonly endpoint = 'authenticate';

	private readonly router = inject(Router);

	private readonly socialAuthService = inject(SocialAuthService);

	private readonly tokenService = inject(TokenService);

	private readonly userPermissionsService = inject(UserPermissionsService);

	private readonly userPermissionsStore = inject(UserPermissionsStore);

	forgotPassword(email: string) {
		return this.httpClient.post(`authenticate/forgot-password`, { email }, { headers: { [HeaderParameter.PUBLIC]: 'true' } });
	}

	isAuthenticated() {
		return !this.tokenService.isTokenExpired();
	}

	login(login: LoginRequest) {
		return this.httpClient
			.post<Response<LoginResponse>>(this.endpoint, login, {
				headers: {
					[HeaderParameter.HIDE_MESSAGE]: 'true',
					[HeaderParameter.PUBLIC]: 'true',
				},
			})
			.pipe(
				tap((response) => {
					if (response.success) {
						if (response.data?.token) {
							this.tokenService.setToken(response.data.token);
						}
						if (response.data?.refreshToken) {
							this.tokenService.setRefreshToken(response.data.refreshToken);
						}

						this.tokenService.setRemember(login.remember);
					}
				}),
				switchMap(() => {
					return this.userPermissionsService.getUserPermissions();
				}),
			);
	}

	loginWithSSO(payload: SocialUser, routeToRedirectOnError: string) {
		return this.httpClient
			.post<Response<LoginResponse>>(`authenticate/sso`, payload, {
				headers: {
					[HeaderParameter.HIDE_MESSAGE]: 'true',
					[HeaderParameter.PUBLIC]: 'true',
				},
			})
			.pipe(
				tap((response) => {
					if (response.data) {
						this.tokenService.setToken(response.data.token);
						this.tokenService.setRefreshToken(response.data.refreshToken);
					}
				}),
				switchMap(() => {
					return this.userPermissionsService.getUserPermissions();
				}),
				tap(async () => {
					let url = '/';
					const { returnUrl } = this.activatedRoute.snapshot.queryParams;
					if (returnUrl) {
						url = decodeURIComponent(returnUrl);
					}

					await this.router.navigateByUrl(url);
				}),
				catchError(async (error) => {
					if (error.message === 'auth-e-002') {
						await this.router.navigateByUrl(routeToRedirectOnError, { state: payload });
					}
					throw error;
				}),
			);
	}

	logout() {
		return this.httpClient
			.post(
				`${this.endpoint}/logout`,
				{},
				{
					headers: {
						[HeaderParameter.HIDE_MESSAGE]: 'true',
						[HeaderParameter.PUBLIC]: 'true',
					},
				},
			)
			.pipe(
				tap(() => {
					this.tokenService.removeTokens();
					this.userPermissionsStore.removePermissions();

					this.socialAuthService.authState.subscribe(async (user) => {
						// console.log('Social user: ', user);
						if (user) {
							await this.socialAuthService?.signOut();
						}
					});
				}),
			);
	}

	/**
	 * Refresh token.
	 *
	 * @param token Refresh token.
	 */
	refreshToken(token: string) {
		return this.httpClient
			.post<any>(
				`${this.endpoint}/refresh-token`,
				{},
				{
					headers: {
						[HeaderParameter.AUTHORIZATION]: token,
						[HeaderParameter.PUBLIC]: 'true',
					},
				},
			)
			.pipe(
				map((response) => {
					this.tokenService.setToken(response.data.token);
					this.tokenService.setRefreshToken(response.data.refreshToken);

					const refreshToken = this.tokenService.getRemember();
					if (!refreshToken.trim()?.length) {
						this.tokenService.setRemember(true);
					}

					// Navigate to the same url where user was trying to navigate before token expired notice.
					// this.permissionsService.getUserPermissions(this.router.url);

					return response;
				}),
			);
	}
}
