import { Injectable, inject } from '@angular/core';

import { ApiAction } from '@sulser-print/constants/api-actions';
import { ApiResourceModule } from '@sulser-print/constants/api-modules/api-resource-modules';
import { HeaderParameter } from '@sulser-print/constants/header-parameters';
import { UserPermission } from '@sulser-print/models/permission/user-permission';
import { Response } from '@sulser-print/models/response';
import { tap } from 'rxjs';

import { RequestService } from './request.service';
import { UserPermissionsStore } from './user-permissions-store';

@Injectable({ providedIn: 'root' })
export class UserPermissionsService extends RequestService {
	private readonly endPoint = ApiResourceModule.PERMISSIONS;

	private readonly userPermissionsStore = inject(UserPermissionsStore, { optional: true });

	getUserPermissions() {
		return this.httpClient
			.get<
				Response<Array<UserPermission>>
			>(`${this.endPoint}/${ApiAction.READ}/user_authorizations`, { headers: { [HeaderParameter.HIDE_MESSAGE]: 'true' } })
			.pipe(
				tap((response) => {
					this.userPermissionsStore?.setPermissions(response.data ?? []);
				}),
			);
	}
}
